import React from 'react'

import CustomLink from 'components/Link/CustomLink'

import style from './Homeowner.module.scss'

type HomeownerProps = {
  homeData: any
}

const Homeowner: React.FC<HomeownerProps> = ({ homeData }) => {
  return (
    <div className={`${style.homeownerSection} ${'section'}`} id="homeowner">
      <div className={`${style.homeownerContent}`}>
        {homeData && homeData.homeowner && (
          <>
            <span className="section-label-sm blue">
              {homeData.homeowner.label}
            </span>
            <div className={style.homeownerTitle}>
              <h2 className="section-title-sm">{homeData.homeowner.title}</h2>
            </div>
            <div className={style.homeownerSubTitle}>
              <span className={`${style.subtitle} section-subtitle-sm`}>
                {homeData.homeowner.subtitle}
              </span>
            </div>
            <CustomLink
              className={`${style.homeownerBtn} btn-primary btn-cta`}
              href={homeData.homeowner.link.url}
              id="homepage_become_homeowner"
              prefetch={false}
              title={homeData.homeowner.link.title}
            >
              {homeData.homeowner.link.title}
            </CustomLink>
          </>
        )}
      </div>
    </div>
  )
}

export default Homeowner
