import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { Stream } from '@cloudflare/stream-react'

import CustomLink from 'components/Link/CustomLink'

import styles from './MultimediaBanner.module.scss'

export type MultimediaBannerProps = {
  label?: string
  description?: string
  title: string
  video?: string
  thumbnail: string
  link?: {
    target: string
    title: string
    url: string
  }
  isHomePage?: boolean
}

const MultimediaBanner = (props: MultimediaBannerProps) => {
  const {
    label,
    link,
    description,
    thumbnail,
    title,
    video,
    isHomePage = false,
  } = props
  const hasLabel = !!label
  const hasDescription = !!description
  const hasLink = !!link?.title
  const hasVideo = !!video

  return (
    <section
      className={classNames(styles.section, {
        [styles.homePageSection]: isHomePage,
      })}
    >
      <div className={styles.content}>
        <div className={styles.info}>
          {hasLabel && (
            <span className={classNames('section-label blue', styles.label)}>
              {label}
            </span>
          )}
          <h3 className={classNames(styles.title)}>{title}</h3>
          {hasDescription && (
            <p
              className={classNames('section-subtitle-sm', styles.description)}
            >
              {description}
            </p>
          )}
          {hasLink && (
            <CustomLink
              className={styles.link}
              href={link.url}
              target={link.target}
            >
              {link.title}
            </CustomLink>
          )}
        </div>
        <div
          className={classNames(styles.mediaWrapper, {
            [styles.videoWrapper]: hasVideo,
            [styles.imgWrapper]: !hasVideo,
          })}
        >
          {hasVideo ? (
            <div className={styles.video} data-testid="video">
              <Stream
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
                alt=""
                controls
                frameBorder="0"
                poster={thumbnail}
                src={video}
                title={title}
              />
            </div>
          ) : (
            <Image
              alt={title || ''}
              layout="fill"
              sizes="(max-width: 1100px) 100vw, 40vw"
              src={thumbnail}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default MultimediaBanner
