import React from 'react'

// Components
import CustomLink from 'components/Link/CustomLink'

// Styles
import style from './VYContestBanner.module.scss'

type HomepageBannerProps = {
  data: {
    display: boolean
    body: string
    link: string
    background_color: string
  }
}

const HomepageBanner: React.FC<HomepageBannerProps> = (props) => {
  const { data } = props

  const { background_color, body, link, display } = data

  if (!display) return null

  return (
    <CustomLink
      className={`${style.banner} ${style.homebanner}`}
      href={link}
      style={{
        backgroundColor: background_color,
      }}
    >
      <span className={style.body} dangerouslySetInnerHTML={{ __html: body }} />
    </CustomLink>
  )
}

export default HomepageBanner
