import React, { useState, useEffect } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'

import style from './RegionalLinks.module.scss'

import { getAutoPageRegionName } from 'utils/locationLanding/pageData'

import PlusTeal from 'assets/icons/icon-plusTeal.svg'
import MinusTeal from 'assets/icons/icon-minusTeal.svg'

import type { RegionsData } from 'types/locationLanding'

type RegionListProps = {
  regionData: RegionsData
  isAutoLocationLanding?: boolean
}

const RegionList: React.FC<RegionListProps> = ({
  regionData,
  isAutoLocationLanding,
}) => {
  const router = useAugmentedRouter()

  const [isOpen, setIsOpen] = useState(false)

  // Close dropdown when changing page
  useEffect(() => {
    setIsOpen(false)
  }, [router.asPath])

  return (
    <div
      className={style.item}
      key={regionData.name}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          setIsOpen(!isOpen)
        }
      }}
      role="button"
    >
      <h3
        className={`${isAutoLocationLanding ? style.autoPageRegion : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        {!isOpen ? (
          <PlusTeal className={style.icon} height={13} width={13} />
        ) : (
          <MinusTeal className={style.icon} height={13} width={13} />
        )}
        <span>
          {isAutoLocationLanding
            ? getAutoPageRegionName(regionData.name)
            : regionData.name}
        </span>
      </h3>
      <div className={`${style.linksWrapper} ${isOpen ? style.isOpen : ''}`}>
        <div className={`${style.links} ${isOpen ? style.isOpen : ''}`}>
          {regionData.links?.map((link) => (
            <li key={link.title}>
              <CustomLink
                href={`/vacation-rentals/${link.href}`}
                prefetch={false}
                title={link.title}
              >
                {link.title}
              </CustomLink>
            </li>
          ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(RegionList)
