import Banner from 'components/Banner/Banner'

import styles from './HomepagePromotionBanner.module.scss'

import promotionData from 'constants/promotionData'

export type HomepagePromotionBannerProps = {
  content?: string
}

const bannerContent = `Take $50 off your next trip. <a class="your-link-class" href="/promotions/${promotionData[1].id}" target="_blank">Sign up to save.</a>`

const HomepagePromotionBanner = (props: HomepagePromotionBannerProps) => {
  const { content = bannerContent } = props

  return (
    <Banner
      className={styles.wrapper}
      id="AB_TEST_GP-936"
      style={{
        display: 'none',
      }}
    >
      <section
        className={styles.banner}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Banner>
  )
}

export { HomepagePromotionBanner }
