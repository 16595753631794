import React, { useContext, useEffect } from 'react'
import Head from 'next/head'
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'

import { PageHistoryContext } from 'context/PageHistoryContext'

import HomeHero from 'components/Home/Hero/HomeHero'
import HomepageBanner from 'components/Home/VYContest/HomepageBanner'
import EvolveDifference from 'components/EvolveDifference/EvolveDifference'
import RecommendedProperties from 'components/Home/Recommended-Properties/Recommended-Properties'
import Header from 'components/Header/Header'
import Gtm from 'components/scripts/Gtm'
import MultimediaBanner from 'components/MultimediaBanner/MultimediaBanner'
import { HomepagePromotionBanner } from 'components/AB-936/HomepagePromotionBanner/HomepagePromotionBanner'
import GuestReviews from 'components/Home/Guest-Reviews/GuestReviews'
import RecommendedDestinationsCarousel from 'components/Carousels/RecommendedDestinationsCarousel'
import Homeowner from 'components/Home/Homeowner/Homeowner'
import Footer from 'components/Footer/Footer'
import RegionalLinks from 'components/RegionalLinks/RegionalLinks'

import { HOME_PAGE_IDENTIFIER } from 'config/cms'

import { fetchFooterData, fetchGlobalOptionsData } from 'utils/staticData'
import { getRegionsData } from 'utils/regionalLinks'

export const getStaticProps: GetStaticProps = async () => {
  const homePage = await fetch(
    `${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-json/wp/v2/pages/?page_identifier=${HOME_PAGE_IDENTIFIER}`,
  )

  const data = await homePage.json()
  const footerData = await fetchFooterData()
  const globalOptionsData = await fetchGlobalOptionsData()
  // Regional Links data
  const regionsData = await getRegionsData()

  return {
    props: {
      homeData: data[0].acf,
      footerData: {
        menus: footerData,
        options: globalOptionsData.acf.global_footer_group,
      },
      recommendedDestinations:
        globalOptionsData.acf.global_recommended_destinations,
      evolveDifference: globalOptionsData.acf.global_evolve_difference,
      seoData: data[0].yoast_meta,
      lastModifiedDate: new Date().toString(),
      regionsData,
    }, // will be passed to the page component as props
    revalidate: process.env.DEFAULT_REVALIDATION_PERIOD
      ? parseInt(process.env.DEFAULT_REVALIDATION_PERIOD)
      : undefined,
  }
}

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  homeData,
  footerData,
  recommendedDestinations,
  evolveDifference,
  seoData,
  regionsData,
}) => {
  const { pageHistory } = useContext(PageHistoryContext)

  useEffect(() => {
    // first check if history has updated, then if on first page allow default referrer, otherwise pass previous page to referrer
    if (
      pageHistory[pageHistory.length - 1]?.split('?')[0] ===
      window.location.href.split('?')[0]
    ) {
      if (pageHistory.length === 1) {
        window.analytics?.page('Home Page')
      } else {
        window.analytics?.page('Home Page', {
          referrer: pageHistory[pageHistory.length - 2],
        })
      }
    }
  }, [pageHistory])

  return (
    <>
      <Head>
        <title>
          {seoData ? seoData.yoast_wpseo_title : 'Evolve Vacation Rental'}
        </title>
        {seoData ? (
          <meta
            content={seoData.yoast_wpseo_metadesc}
            name="description"
          ></meta>
        ) : (
          <meta name="description"></meta>
        )}
        <link href={`${process.env.NEXT_PUBLIC_ORIGIN}`} rel="canonical" />
      </Head>
      <Gtm pageType="home page" />
      <HomepagePromotionBanner />
      <Header transparent />
      <HomeHero homeData={homeData} />
      <HomepageBanner data={homeData?.homepage_banner_group} />
      <EvolveDifference
        evolveDifference={evolveDifference}
        small={false}
        styleClass="homepage"
      />
      {homeData && (
        <MultimediaBanner isHomePage {...homeData.homepage_video_group} />
      )}
      <RecommendedProperties homeData={homeData} />
      <GuestReviews homeData={homeData} />
      <RecommendedDestinationsCarousel
        id="homepage_recommended_destinations"
        recommendedDestinations={recommendedDestinations}
        useGradient={true}
      />
      {homeData?.homeowner?.enabled && <Homeowner homeData={homeData} />}
      {homeData?.homepage_multimedia_group?.enabled && (
        <MultimediaBanner {...homeData.homepage_multimedia_group} isHomePage />
      )}
      <RegionalLinks
        isHomepage={true}
        regionsData={regionsData}
        sectionSubTitle={`Choose a region, browse vacation destinations, and start planning
            your next adventure.`}
        sectionTitle={`Explore Top Vacation Spots Across North America`}
      />
      <Footer footerData={footerData} />
    </>
  )
}

export default Home
