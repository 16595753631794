import React from 'react'
import classNames from 'classnames'

import style from './Recommended.module.scss'

// Components
import PropertyCarousel from '../../Carousels/Property-Carousel'

type RecommendedPropertiesProps = {
  homeData: any
  className?: string
}

const RecommendedProperties: React.FC<RecommendedPropertiesProps> = ({
  homeData,
  className,
}) => {
  let propertyData: any[] = []
  if (
    homeData &&
    homeData.recommended_property &&
    homeData.recommended_property.featured_property
  ) {
    propertyData = homeData.recommended_property.featured_property.map(
      (property: any) => {
        return {
          ...property,
        }
      },
    )
  }

  return (
    <section
      className={classNames('section', style.section, className)}
      id="properties"
    >
      <div className={`${style.content} content`}>
        {homeData && homeData.recommended_property && (
          <>
            <div className={`${style.recommendedTitle} carousel-title`}>
              {homeData.recommended_property.label && (
                <strong className={'section-label-sm blue'}>
                  {homeData.recommended_property.label}
                </strong>
              )}
              <h2 className={'section-title-sm'}>
                {homeData.recommended_property.title}
              </h2>
              <span className={`${style.travelDetails} section-subtitle-sm`}>
                {homeData.recommended_property.subtitle}
              </span>
            </div>
            {propertyData && propertyData.length && (
              <PropertyCarousel
                data={propertyData}
                id="homepage_property_carousel"
              />
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default RecommendedProperties
