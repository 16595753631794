import React, { useMemo } from 'react'

import { useSelect } from 'store/index'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './RegionalLinks.module.scss'
import RegionList from './RegionList'

import type { RegionsData } from 'types/locationLanding'

type RegionalLinksProps = {
  regionsData: RegionsData[]
  sectionLabel?: string
  sectionTitle?: string
  sectionSubTitle?: string
  isHomepage?: boolean
}

const RegionalLinks: React.FC<RegionalLinksProps> = ({
  regionsData,
  sectionLabel,
  sectionTitle,
  sectionSubTitle,
  isHomepage,
}) => {
  const router = useAugmentedRouter()

  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  const isAutoLocationLanding = useMemo(
    () => router.asPath.split('/')[1] === 'vacation-rentals',
    [router.asPath],
  )

  // Divide regionsData based on page width so that columns can flex properly
  const renderCols = useMemo(() => {
    // 1 column, mobile
    if (width < 600) {
      return (
        <div className={style.col}>
          {regionsData.map((region) => (
            <RegionList
              isAutoLocationLanding={isAutoLocationLanding}
              key={region.name}
              regionData={region}
            />
          ))}
        </div>
      )
    } else {
      let colList: React.ReactElement[][] = []
      // 3 columns, desktop
      if (width > 768) {
        const third = Math.ceil(regionsData.length / 3)

        return [
          <div className={style.col} key={0}>
            {regionsData.slice(0, third).map((region) => (
              <RegionList
                isAutoLocationLanding={isAutoLocationLanding}
                key={region.name}
                regionData={region}
              />
            ))}
          </div>,
          <div className={style.col} key={1}>
            {regionsData.slice(third, third * 2).map((region) => (
              <RegionList
                isAutoLocationLanding={isAutoLocationLanding}
                key={region.name}
                regionData={region}
              />
            ))}
          </div>,
          <div className={style.col} key={2}>
            {regionsData.slice(third * 2, regionsData.length).map((region) => (
              <RegionList
                isAutoLocationLanding={isAutoLocationLanding}
                key={region.name}
                regionData={region}
              />
            ))}
          </div>,
        ]
      } else {
        colList = [[], []]
        // 2 columns, tablet
        for (let i = 0; i < regionsData.length; i++) {
          colList[i & 1].push(
            <RegionList
              isAutoLocationLanding={isAutoLocationLanding}
              key={regionsData[i].name}
              regionData={regionsData[i]}
            />,
          )
        }
        return (
          <>
            {colList.map((col, i) => (
              <div className={style.col} key={i}>
                {col}
              </div>
            ))}
          </>
        )
      }
    }
  }, [width, regionsData, isAutoLocationLanding])

  return (
    <section className={style.regionalLinksSection} id="RegionalLinks">
      <div
        className={`${style.regionalLinksWrapper} ${
          isHomepage ? 'section' : ''
        }`}
      >
        <div className={`${style.flexContainer} ${style.block}`}>
          {sectionLabel && (
            <span className="section-label-sm blue">{sectionLabel}</span>
          )}
          {sectionTitle && (
            <h2
              className={`section-title-sm ${
                !sectionSubTitle ? style.noSubTitle : ''
              }`}
            >
              {sectionTitle}
            </h2>
          )}
          {sectionSubTitle && (
            <h5 className={`section-subtitle-sm`}>{sectionSubTitle}</h5>
          )}
        </div>
        <div className={style.flexContainer}>{renderCols}</div>
      </div>
    </section>
  )
}

export default RegionalLinks
