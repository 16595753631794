import React, { useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import { BannerContext } from 'context/BannerContext'

import styles from './Banner.module.scss'

import IconClose from 'assets/icons/icon-close.svg'

type BannerProps = {
  id: string
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Banner: React.FC<BannerProps> = ({ children, id, className, style }) => {
  const { bannerEl, getIsBannerOpen, dispatch } = useContext(BannerContext)

  useEffect(() => {
    dispatch({
      type: 'banner_mounted',
      payload: id,
    })

    return () =>
      dispatch({
        type: 'banner_unmounted',
        payload: id,
      })
  }, [dispatch, id])

  const isBannerOpen = getIsBannerOpen(id)

  const shouldRenderBanner = bannerEl && isBannerOpen

  if (!shouldRenderBanner) return null

  return createPortal(
    <article
      className={classNames(styles.banner, className)}
      id={id}
      style={style}
    >
      {children}
      <button
        aria-label="dismiss banner button"
        className={styles.closeButton}
        onClick={() =>
          dispatch({
            type: 'dismiss_banner',
            payload: id,
          })
        }
      >
        <IconClose />
      </button>
    </article>,
    bannerEl,
  )
}

export default Banner
