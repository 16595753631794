import React from 'react'

import style from './GuestReviews.module.scss'

// Components
import ReviewCarousel from '../../Carousels/Review-Carousel'

type GuestReviewsProps = {
  homeData: any
}

const GuestReviews: React.FC<GuestReviewsProps> = ({ homeData }) => {
  return (
    <div className={`${style.guestReviewSection} ${'section'}`} id="reviews">
      <div className={`${style.guestContent} ${'content'}`}>
        {homeData && homeData.guest_reviews && (
          <ReviewCarousel
            id="guest_reviews"
            reviewData={homeData.guest_reviews}
            type="Guest"
          />
        )}
      </div>
    </div>
  )
}

export default GuestReviews
