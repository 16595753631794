import React from 'react'
import Image from 'next/image'

import style from './ReviewItem.module.scss'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

const ReviewItem = ({
  image,
  label,
  review,
  avatar,
  name,
  user_since,
  type,
}) => {
  return (
    <div className={`${style.reviewWrapper} `}>
      <div className={style.reviewImage}>
        <div className="image">
          <Image
            alt=""
            fill
            loader={cloudinaryLoader}
            sizes="(max-width: 900px) 100vw, (max-width: 1205px) 549px, 657px"
            src={getWordpressCloudinaryId(image)!}
            style={{
              objectFit: 'cover',
            }}
          />
        </div>
      </div>
      <div className={style.reviewContentWrapper}>
        <div className={style.reviewContent}>
          <img alt="" className={style.quote} src="/assets/icons/quote.svg" />
          <span className="section-label-sm blue">{label}</span>
          <p className={style.reviewContentQuote}>{review}</p>
          <div className={type ? style.reviewerInfoSm : style.reviewerInfo}>
            <div
              className={style.avatarImage}
              style={{ backgroundImage: `url(${avatar})` }}
            ></div>
            <div className={style.avatarDetails}>
              <span>{name}</span>
              <span>{user_since}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewItem
