import React, { useMemo } from 'react'
import type { ImageLoader } from 'next/image'
import Image from 'next/image'
import Head from 'next/head'

import SearchBar from 'components/Search/SearchBar'
import CustomLink from 'components/Link/CustomLink'

import style from './HomeHero.module.scss'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'
import generateImgAttrs from 'utils/images/generateImgAttrs'

import SmallCaret from 'assets/icons/icon-smallCaret.svg'

export type HomeHeroProps = {
  homeData: any // TODO: Type this correctly
}

const mobileImageLoader: ImageLoader = ({ src, width, quality }) =>
  `https://res.cloudinary.com/evolve-vacation-rental-network/images/f_auto,c_fill,g_north_west,w_${width},h_1060,q_${
    quality || 'auto'
  }/${src}`

const Hero: React.FC<HomeHeroProps> = ({ homeData }) => {
  const mobileImgAttributes = useMemo(
    () =>
      generateImgAttrs({
        src:
          getWordpressCloudinaryId(
            homeData.homepage_hero_group.hero_image.url,
          ) ?? '',
        layout: 'fill',
        loader: mobileImageLoader,
      }),
    [homeData.homepage_hero_group.hero_image.url],
  )

  const desktopImgAttributes = useMemo(
    () =>
      generateImgAttrs({
        src:
          getWordpressCloudinaryId(
            homeData.homepage_hero_group.hero_image.url,
          ) ?? '',
        layout: 'fill',
        loader: cloudinaryLoader,
      }),
    [homeData.homepage_hero_group.hero_image.url],
  )

  return (
    homeData &&
    homeData.homepage_hero_group && (
      <section>
        <div className={style.hero}>
          <Image
            alt=""
            className={style.hero__desktop__image}
            fill
            loader={cloudinaryLoader}
            sizes="100vw"
            src={
              getWordpressCloudinaryId(
                homeData.homepage_hero_group.hero_image.url,
              ) ?? ''
            }
            style={{
              objectFit: 'cover',
              objectPosition: 'left top',
            }}
          />
          <Image
            alt=""
            className={style.hero__mobile__image}
            fill
            loader={mobileImageLoader}
            sizes="100vw"
            src={
              getWordpressCloudinaryId(
                homeData.homepage_hero_group.hero_image.url,
              ) ?? ''
            }
            style={{
              objectFit: 'cover',
              objectPosition: 'left top',
            }}
          />
          <Head>
            {/** Overriding preload headers for art-direction: https://web.dev/preload-responsive-images/ */}
            <link
              as="image"
              href={
                mobileImgAttributes.srcSet ? undefined : mobileImgAttributes.src
              }
              imageSizes={mobileImgAttributes.sizes}
              imageSrcSet={mobileImgAttributes.srcSet}
              key={
                '__nimg-' +
                mobileImgAttributes.src +
                mobileImgAttributes.srcSet +
                mobileImgAttributes.sizes
              }
              media="(max-width: 599px)"
              rel="preload" // only preload on mobile
            />
            <link
              as="image"
              href={
                desktopImgAttributes.srcSet
                  ? undefined
                  : desktopImgAttributes.src
              }
              imageSizes={desktopImgAttributes.sizes}
              key={
                '__nimg-' +
                desktopImgAttributes.src +
                desktopImgAttributes.srcSet +
                desktopImgAttributes.sizes
              }
              media="(min-width: 600px)"
              rel="preload" // only preload on desktop
            />
          </Head>

          <div className={style.heroContentContainer}>
            <div className={style.heroContent}>
              <span className={'section-label'}>
                {homeData.homepage_hero_group.section_label}
              </span>
              <h1 className={style.title}>
                {homeData.homepage_hero_group.section_title}
              </h1>
              <span className={style.subtitle}>
                {homeData.homepage_hero_group.section_subtitle}
              </span>
              <SearchBar />
              <div className={style.viewLocation}>
                <CustomLink
                  className="btn-cta"
                  href={homeData.homepage_hero_group.hero_location_link}
                  prefetch={false}
                  title={homeData.homepage_hero_group.hero_location}
                >
                  {homeData.homepage_hero_group.hero_location}
                  <SmallCaret height={19} width={19} />
                </CustomLink>
              </div>
            </div>
          </div>
          {/* Mask commented out temporarily for the Vacation You COntest Banner */}
          {/* <div className={style.mask}>
          <DesktopMask className={style.desktopMask} />
        </div> */}
        </div>
      </section>
    )
  )
}

export default Hero
