import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import dayjs from 'dayjs'

import { getListingsFacetsCounts, getTopFacets } from './topFacets'

import { faqAmenities } from 'config/LocationLanding'

// import { fetchListingsReviews } from 'utils/staticData'
import { hasEvenLengthSize } from 'utils/arrayUtils'

import type {
  DetailedLocationData,
  ReviewMap,
  CountObject,
} from 'types/locationLanding'
import type { InitialSearch, AlgoliaListing, Review } from 'types/externalData'

// Utils

const MAX_TOP_LISTING = 6
const MIN_TOP_LISTING = 4
const MIN_RATE = 4

// Create listingID/review map. Only show 4*/5* reviews in last year
const getTopReviewsMap = (
  reviews: (Review[] | null)[],
  top: number = MAX_TOP_LISTING,
) => {
  const reviewsMap = reviews.reduce((reviewsObj, reviewList) => {
    if (reviewList) {
      const bestReview = reviewList
        .filter(isCreatedInLastYear)
        .filter(hasReviewDetail)
        .filter(isTopRating)
        ?.sort(sortReviewsByCreatedDT)[0]
      if (bestReview) {
        reviewsObj[reviewList[0].listing] = bestReview
      }
    }
    return reviewsObj
  }, {} as ReviewMap)

  const topListingsId = Object.keys(reviewsMap)
    .sort((currentReview, nextReview) =>
      sortReviewsByCreatedDT(reviewsMap[currentReview], reviewsMap[nextReview]),
    )
    .slice(0, top)
  if (!hasEvenLengthSize(topListingsId)) {
    topListingsId.pop()
  }

  if (topListingsId.length < MIN_TOP_LISTING) {
    return {}
  }

  return pick(reviewsMap, topListingsId)
}

const isTopRating = (review: Review): boolean => +review.rating >= MIN_RATE

const hasReviewDetail = (review: Review): boolean =>
  !!review.reviewDetail.split(/<br>/)[0].trim()

const isCreatedInLastYear = (review: Review): boolean => {
  const createdAt = dayjs(review.createdDt || '')
  const now = dayjs()
  const oneYearAgo = now.subtract(1, 'year')

  return createdAt.isBetween(oneYearAgo, now)
}

const filterListingsBasedOnTopReviews = (
  reviews: ReviewMap,
  listings: AlgoliaListing[],
): AlgoliaListing[] => {
  const listingIdWithReviews = Object.keys(reviews)
  if (listingIdWithReviews.length < MIN_TOP_LISTING) {
    return listings
  }

  return listings.filter((listing) =>
    listingIdWithReviews.includes(listing.objectID),
  )
}

/*
  Sort listings by date decrementally
  (from most recent to least recent)
*/
const sortListingsByReviewDate = (
  listings: AlgoliaListing[],
  reviews: ReviewMap,
): AlgoliaListing[] =>
  listings.sort((currentListing, nextListing) => {
    const [currentReview, nextReview] = [
      reviews[currentListing.objectID],
      reviews[nextListing.objectID],
    ]
    const someReviewNotExist = !currentReview || !nextReview
    if (someReviewNotExist) return 1
    return sortReviewsByCreatedDT(currentReview, nextReview)
  })

const sanitizeTopListingsLength = (
  listings: AlgoliaListing[],
  maxSize: number = MAX_TOP_LISTING,
): AlgoliaListing[] => {
  const topListings = listings.slice(0, maxSize)
  if (!hasEvenLengthSize(topListings)) {
    topListings.pop()
  }

  return topListings
}
// Get page-level data from listings fetched in G.S.P.
export const getListingData = async (listings: AlgoliaListing[]) => {
  // let topReviewsMap = {}

  // let topRatedListings = getTopRatedListings(listings)
  // if (topRatedListings.length >= MIN_TOP_LISTING) {
  //   const listingsIds = topRatedListings.map(({ objectID }) => objectID)
  //   const reviewsLists = await fetchListingsReviews(listingsIds)
  //   // TODO Review changed from createdDt: string to createdAt: string
  //   // ignoring for now in the node upgrade
  //   // @ts-expect-error
  //   topReviewsMap = getTopReviewsMap(reviewsLists)
  //   topRatedListings = filterListingsBasedOnTopReviews(
  //     topReviewsMap,
  //     topRatedListings,
  //   )
  //   topRatedListings = sanitizeTopListingsLength(topRatedListings)
  //   topRatedListings = sortListingsByReviewDate(topRatedListings, topReviewsMap)
  // } else {
  //   topRatedListings = []
  // }

  return {
    faqAmenitiesCounts: getFaqAmenitiesCounts(listings),
    topFacets: getTopFacets(listings.length, getListingsFacetsCounts(listings)),
    // topRatedListings,
    // topReviewsMap,
  }
}

// Faq functions
// Add/Incriment amenity count
const addFaqAmenity = (faqCounts: CountObject, key: string) =>
  (faqCounts[key] = faqCounts[key] ? faqCounts[key] + 1 : 1)

// Create dictionary of amenity counts for FAQs
export const getFaqAmenitiesCounts = (listings: any[]) => {
  return listings.reduce((faqCounts: CountObject, listing: any) => {
    let allAmens: string[] = []

    for (const amenCategory in listing['amenities']) {
      allAmens = [...allAmens, ...listing['amenities'][amenCategory]]
    }

    Object.keys(faqAmenities).forEach((key) => {
      switch (faqAmenities[key].checkType) {
        case 'includes':
          if (allAmens.includes(faqAmenities[key].amenities)) {
            addFaqAmenity(faqCounts, key)
          }
          break
        case 'includesTwo':
          if (
            allAmens.includes(faqAmenities[key].amenities[0]) &&
            allAmens.includes(faqAmenities[key].amenities[1])
          ) {
            addFaqAmenity(faqCounts, key)
          }
          break
        case 'includesAny':
          if (
            allAmens.filter((el) => faqAmenities[key].amenities.includes(el))
              .length
          ) {
            addFaqAmenity(faqCounts, key)
          }
          break
        default:
          break
      }
    })

    return faqCounts
  }, {})
}

// Page slug/path manipulation functions
export const nameToSlug = (cityName: string) =>
  cityName.toLowerCase().replaceAll(' ', '-')

export const slugToName = (cityNameSlug: string) => {
  return cityNameSlug
    .split('-')
    .map(([first, ...rest]) => first.charAt(0).toUpperCase() + rest.join(''))
    .join(' ')
}

export const pathToName = (path: string) => {
  const splitPath = path.split('/').filter(Boolean)
  const nameData = { city: '', territory: '' }

  if (splitPath[0] === 'us') {
    nameData.city = splitPath[2]
    nameData.territory = splitPath[1]
  }
  if (splitPath[0] === 'mx') {
    nameData.city = splitPath[1]
    nameData.territory = splitPath[0]
  }
  if (splitPath[1] === 'vi') {
    nameData.city = splitPath[2]
    nameData.territory = splitPath[1]
  }

  return `${slugToName(nameData.city)}, ${nameData.territory.toUpperCase()}`
}

// Regional Links functions
export const getAutoPageRegionName = (regionName: string) => {
  switch (regionName) {
    case 'Northeast':
    case 'New England':
    case 'Midwest':
    case 'Great Lakes':
    case 'Gulf Coast':
    case 'Caribbean':
    case 'Mexico':
      return `${regionName} Vacation Spots `
    case 'The South':
    case 'Pacific Northwest':
    case 'Southwest':
      return `Vacation Spots in the ${regionName.replace('The', '')}`
    case 'The West':
      return `Vacation Spots in the Western U.S.`
    default:
      return regionName
  }
}

// Page Hero functions
export const getInitialSearch = (
  cityData: DetailedLocationData,
  stateData: DetailedLocationData,
  countryData: DetailedLocationData,
) => {
  const searchData = {
    address: {
      address: `${cityData.name}${stateData.iso ? `, ${stateData.iso}` : ''}, ${
        countryData.iso === 'US' ? 'USA' : countryData.iso
      }`,
      state: `${stateData.name}`,
      state_short: `${stateData.iso}`,
      country: `${countryData.name}`,
      country_short: `${countryData.iso}`,
    },
    check_in: '',
    check_out: '',
    guests: {
      adults: '0',
      children: '0',
      infants: '0',
      pets: '0',
    },
  }

  if (cityData.lat && cityData.lng) {
    searchData['address'] = {
      ...searchData['address'],
      ...{ lat: +cityData.lat, lng: +cityData.lng },
    }
  }

  return searchData as InitialSearch
}

// TopRated functions
// Top rated w/most reviews
export const getTopRatedListings = (
  listings: AlgoliaListing[] = [],
  topRate: number = MIN_RATE,
): Array<AlgoliaListing> => {
  return orderBy(
    listings,
    ['Average Rating', 'Number of Reviews'],
    ['desc', 'desc'],
  ).filter((listing) => listing['Average Rating'] >= topRate)
}

// Msc. Functions
// Turn array of objects into a map object using an one of the object's keys (propKey)
export const arrToObj = (arr: { [key: string]: any }[], propKey: string) =>
  arr.reduce((obj, item) => ((obj[item[propKey]] = item), obj), {})

const sortReviewsByCreatedDT = (currentReview: Review, nextReview: Review) => {
  return (
    Number(new Date(nextReview.createdDt)) -
    Number(new Date(currentReview.createdDt))
  )
}
