import React, { useEffect, useState } from 'react'

import { useSelect } from 'store/index'

import Arrow from './Arrow'
import ReviewItem from './ReviewItem'
import style from './Review-Carousel.module.scss'

const ReviewCarousel = (props) => {
  const { reviewData, type, dark, id } = props

  const [slidesToShow] = useState(1)
  const [height, setHeight] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(0)
  const [distance, setDistance] = useState(0)
  const [isTouchDevice, setIsTouchDevice] = useState(false)
  const [slides, setSlides] = useState(null)
  // Swiping on mobile devices
  const [xStart, setXStart] = useState(0)

  const width = useSelect((state) => state.uiState.width)

  useEffect(() => {
    const checkTouchDevice = () => {
      if ('ontouchstart' in window) {
        setIsTouchDevice(true)
      } else {
        setIsTouchDevice(false)
      }
    }

    setHeight(460)
    setDistance(100)
    // checking for touch devices
    // if it is not a touch devices
    // then carousel arrows must stay on page
    checkTouchDevice()
  }, [props, currentPosition, width])

  useEffect(() => {
    const setCarousel = () => {
      if (reviewData.reviews) {
        const carouselSlides = reviewData.reviews.map(
          (
            { label, image, review, reviewer: { avatar, name, user_since } },
            i,
          ) => (
            <ReviewItem
              avatar={avatar}
              image={image}
              key={i}
              label={label}
              name={name}
              review={review}
              type={type}
              user_since={user_since}
            />
          ),
        )

        return carouselSlides
      }
    }
    if (!slides) {
      setSlides(setCarousel())
    }
  }, [slides, reviewData.reviews, type])

  const createIndicators = () => {
    const dots: JSX.Element[] = []
    for (let i = 0; i < reviewData.reviews.length; i++) {
      dots.push(
        <span
          className={`${dark ? style.dotLight : style.dot} ${
            currentPosition === i ? style.dotActive : ''
          }`}
          key={i}
        ></span>,
      )
    }
    return dots
  }

  const movePosition = (pos) => {
    if (pos === 'neg') {
      if (currentPosition !== 0) {
        const position = currentPosition - slidesToShow
        setCurrentPosition(position)
      }
    } else {
      if (currentPosition !== reviewData.reviews.length - slidesToShow) {
        const position = currentPosition + slidesToShow
        setCurrentPosition(position)
      }
    }
  }

  const touchStart = (e) => {
    setXStart(e.changedTouches[0].clientX)
  }

  const touchEnd = (e) => {
    if (width <= 1023) {
      const end = e.changedTouches[0].clientX
      if (xStart > end) {
        if (currentPosition !== reviewData.reviews.length - slidesToShow) {
          const position = currentPosition + slidesToShow
          setCurrentPosition(position)
        }
      } else {
        if (currentPosition !== 0) {
          const position = currentPosition - slidesToShow
          setCurrentPosition(position)
        }
      }
    }
  }

  return (
    <div
      className="carousel"
      onTouchEnd={(e) => touchEnd(e)}
      onTouchStart={(e) => touchStart(e)}
    >
      {currentPosition !== 0 && (
        <Arrow
          className={isTouchDevice ? '' : ` ${style.guestCarouselArrowTouch}`}
          dark={dark}
          direction="left"
          id={id}
          onClick={() => movePosition('neg')}
        />
      )}
      <div
        className={`${style.guestCarouselTrack} carousel-track`}
        style={{ height: `${height}px` }}
      >
        {reviewData && (
          <>
            <div
              className={`${style.guestSliderWrapper} slider-wrapper`}
              style={{
                transform: `translateX(-${currentPosition * distance}%)`,
              }}
            >
              {slides}
            </div>
          </>
        )}
        {reviewData && (
          <div className={style.indication}>{createIndicators()}</div>
        )}
      </div>
      {reviewData &&
        currentPosition !== reviewData.reviews.length - slidesToShow && (
          <Arrow
            className={
              isTouchDevice
                ? ` ${style.guestCarouselArrowRight}`
                : ` ${style.guestCarouselArrowRight} ${style.guestCarouselArrowTouch}`
            }
            dark={dark}
            direction="right"
            id={id}
            onClick={() => movePosition('pos')}
          />
        )}
    </div>
  )
}

export default ReviewCarousel
